@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  src:
    url("/src/assets/fonts/Pretendard/Pretendard-Regular.subset.woff2") format("woff2"),
    url("/src/assets/fonts/Pretendard/Pretendard-Regular.subset.woff") format("woff"),
    url("/src/assets/fonts/Pretendard/Pretendard-Regular.otf") format("opentype");
}

@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  src:
    url("/src/assets/fonts/Pretendard/Pretendard-Medium.subset.woff2") format("woff2"),
    url("/src/assets/fonts/Pretendard/Pretendard-Medium.subset.woff") format("woff"),
    url("/src/assets/fonts/Pretendard/Pretendard-Medium.otf") format("opentype");
}

@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 600;
  src:
    url("/src/assets/fonts/Pretendard/Pretendard-SemiBold.subset.woff2") format("woff2"),
    url("/src/assets/fonts/Pretendard/Pretendard-SemiBold.subset.woff") format("woff"),
    url("/src/assets/fonts/Pretendard/Pretendard-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  src:
    url("/src/assets/fonts/Pretendard/Pretendard-Bold.subset.woff2") format("woff2"),
    url("/src/assets/fonts/Pretendard/Pretendard-Bold.subset.woff") format("woff"),
    url("/src/assets/fonts/Pretendard/Pretendard-Bold.otf") format("opentype");
}

@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 800;
  src:
    url("/src/assets/fonts/Pretendard/Pretendard-ExtraBold.subset.woff2") format("woff2"),
    url("/src/assets/fonts/Pretendard/Pretendard-ExtraBold.subset.woff") format("woff"),
    url("/src/assets/fonts/Pretendard/Pretendard-ExtraBold.otf") format("opentype");
}
